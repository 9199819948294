<template>
    <div>
        <!-- Content Header (Page header) -->
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0 text-dark"><i class="nav-icon fas fa-store-alt"></i> Busca de Lojas <small>Listagem de Busca de Lojas</small></h1>
                    </div>
                    <!-- /.col -->
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link to="/dashboard">
                                    <i class="nav-icon fas fa-home"></i> Home
                                </router-link>
                            </li>
                            <li class="breadcrumb-item active"><i class="nav-icon fas fa-store-alt"></i> Busca de Lojas</li>
                        </ol>
                    </div>
                    <!-- /.col -->
                </div>
                <!-- /.row -->
            </div>
            <!-- /.container-fluid -->
        </div>
        <!-- /.content-header -->
        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <div class="card card-primary card-outline">
                    <div class="card-header">
                        <h3 class="card-title"> Listagem de Busca de Lojas</h3>

                        <div class="card-tools">
                            <button type="button" class="btn btn-tool" data-card-widget="collapse" title="Collapse">
                                <i class="fas fa-minus"></i>
                            </button>
                            <button type="button" class="btn btn-tool" data-card-widget="remove" title="Remove">
                                <i class="fas fa-times"></i>
                            </button>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <router-link class="btn btn-app" :to="{ name: 'form-busca_loja', params: { id: 0 }}">
                                <i class="fas fa-users"></i> Cadastrar Busca Loja
                            </router-link>
                        </div>
                        <client-table :options="options">
                            <thead>
                                <tr>
                                    <th>País</th>
                                    <th>UF</th>
                                    <th>Cidade</th>
                                    <th>Bairro</th>
                                    <th>Endereço</th>
                                    <th>Nro</th>
                                    <th>CEP</th>
                                    <th class="min-tablet" style="width: 70px;">Ações</th>
                                </tr>
                            </thead>
                        </client-table>
                    </div>
                    <div class="card-footer">
                    </div>
                    <div class="overlay">
                        <a class="btn btn-app bg-secondary">
                            <i class="fas fa-spinner fa-pulse"></i> carregando...
                        </a>
                    </div>
                </div>
            </div>
            <!-- /.container-fluid -->
        </section>
        <!-- /.content -->
    </div>
</template>

<script>
    export default {
        data: function () {
            return {
                options: {
                    "title": "Busca de Lojas",
                    "ajax": {
                        "url": "/api/busca_loja/datatable",
                        "data": function (d) {
                            return JSON.stringify($.extend({}, d, {
                            }));
                        }
                    },
                    "order": [[ 0, 'asc' ], [ 1, 'asc' ]],
                    "columns": [
                        { "mData": 'pais' },
                        { "mData": 'uf' },
                        { "mData": 'cidade' },
                        { "mData": 'bairro' }, 
                        { "mData": 'endereco' }, 
                        { "mData": 'numero' }, 
                        { "mData": 'cep' },
                        {
                            "bSortable": false,
                            "mData": null,
                            "sClass": "text-center py-0",
                            "mRender": function (data, type, full) {
                                return `<div class="btn-group">
                                    <button onclick="$(this).trigger('edit', ${full.id_busca_loja})" rel="tooltip" title="Editar" class="btn bg-gradient-primary btn-sm"><i class="fas fa-pencil-alt"></i></button>
                                </div>`;
                            }
                        }
                    ]
                }   
            };
        },
    mounted: function () {
        const vm = this;

        $("body").on("edit", function (event, id) { vm.$router.push({ name: 'form-busca_loja', params: { id: id } }).catch(() => { }); });
    }
    };
</script>

<style scoped>
</style>